import { FloatingHTMLOverlay } from "~/components/FloatingHTMLOverlay";

import {
  faAdd,
  faPen,
  faRemove,
  faSpinner,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOnClickOutside from "~/hooks/useClickOutside";
import { useContext, useEffect, useRef, useState } from "react";
import { ParsedAccount, getAvatar } from "~/utils/hive";
import { ImgurData, uploadImage } from "~/utils/images";
import { FloatingPortal } from "@floating-ui/react";
import { CachedList, editList } from "~/utils/leocache";
import { toast } from "react-toastify";
import classNames from "classnames";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { SmallAvatar12 } from "../format/SmallAvatar";
import { useAppStore } from "~/store";

interface ModalProps {
  visibility: boolean;
  setVisibility: (visibility: boolean) => void;
  listDetails: CachedList;
}

export default function EditListModal({
  visibility,
  setVisibility,
  listDetails
}: ModalProps) {
  if (!visibility) return null;

  const [{ activeAccount, keys }, isDarkMode] = useAppStore(store => [
    store.account,
    store.settings.dark
  ]);
  const { signature, publicKey, proxy } = keys;

  const modalRef = useRef<HTMLDivElement>(null);
  const [list, setList] = useState({
    name: listDetails.name,
    description: listDetails.description,
    image: listDetails.image,
    private: listDetails.private,
    users: listDetails.users
  });

  const [image, setImage] = useState<any>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [creating, setCreating] = useState<boolean>(false);

  const updateList = (key: string, value: any) => {
    setList(current => ({
      ...current,
      [key]: value
    }));
  };

  const createReadableImage = () => {
    const object = URL.createObjectURL(image);
    return object;
  };

  useEffect(() => {
    if (!activeAccount) return;

    void (async function () {
      setUploading(true);
      const imgur = await uploadImage(image, activeAccount.name);

      if (!imgur.url) {
        setUploading(false);
        setImage(null);
        return toast("Error while uploading image. Please try again later.", {
          type: "error",
          theme: isDarkMode ? "dark" : "light",
          autoClose: 3_000
        });
      }

      setList(current => ({
        ...current,
        image: imgur.url
      }));
      setUploading(false);
    })();
  }, [image]);

  const [saveLoading, setSaveLoading] = useState(undefined);

  const saveImage = async () => {
    setSaveLoading({ type: "image" });
    const save = await editList(
      activeAccount.name,
      signature,
      publicKey,
      proxy === "hivesigner",
      {
        field: "image",
        type: "update",
        author: activeAccount.name,
        list: listDetails.slug,
        update: list.image.replaceAll("https://i.imgur.com/", "")
      }
    );
    setSaveLoading(undefined);
  };

  const saveTitle = async () => {
    setSaveLoading({ type: "title" });
    const save = await editList(
      activeAccount.name,
      signature,
      publicKey,
      proxy === "hivesigner",
      {
        field: "name",
        type: "update",
        author: activeAccount.name,
        list: listDetails.slug,
        update: list.name
      }
    );
    setSaveLoading(undefined);
  };

  const saveDescription = async () => {
    setSaveLoading({ type: "description" });
    const save = await editList(
      activeAccount.name,
      signature,
      publicKey,
      proxy === "hivesigner",
      {
        field: "description",
        type: "update",
        author: activeAccount.name,
        list: listDetails.slug,
        update: list.description
      }
    );
    setSaveLoading(undefined);
  };

  const savePrivate = async () => {
    setSaveLoading({ type: "private" });
    updateList("private", !list.private);
    const save = await editList(
      activeAccount.name,
      signature,
      publicKey,
      proxy === "hivesigner",
      {
        field: "private",
        type: "update",
        author: activeAccount.name,
        list: listDetails.slug,
        update: !list.private
      }
    );
    setSaveLoading(undefined);
  };

  const [membersVisibility, setMembersVisibility] = useState(false);

  const removeUser = async (user: string) => {
    setSaveLoading({ type: `user${user}` });
    const save = await editList(
      activeAccount.name,
      signature,
      publicKey,
      proxy === "hivesigner",
      {
        field: "users",
        type: "remove",
        author: activeAccount.name,
        list: listDetails.slug,
        update: user
      }
    );
    setSaveLoading(undefined);
    updateList(
      "users",
      list.users.filter(username => username !== user)
    );
  };

  return (
    <FloatingPortal>
      <FloatingHTMLOverlay
        onClick={ev => {
          ev.stopPropagation();
        }}
        className="bg-overlay z-[1000] flex justify-center py-[10vh]"
        lockScroll
      >
        <div
          ref={modalRef}
          className="animate-reveal-form relative py-4 px-6 flex flex-col gap-y-6 rounded-xl border-xl bg-pri dark:bg-pri-d border-pri dark:border-pri w-10/12 h-fit pc:w-3/12 pc:max-h-fit md:w-6/12 sm:w-8/12 xs:w-full tbl:min-w-[420px]"
        >
          <header className="flex items-center justify-between pb-4 border-b border-pri dark:border-zinc-700/[.75]">
            <strong className="text-lg">Edit List {list.name}</strong>
            <div
              onClick={() => setVisibility(false)}
              className="absolute p-2 h-8 w-8 right-4 flex items-center justify-center rounded-full hover:bg-neutral-200 dark:hover:bg-neutral-700 transition-colors cursor-pointer"
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </header>

          {membersVisibility || (
            <div className="flex flex-col gap-y-5">
              {/* image */}
              <div className="flex flex-col gap-y-2">
                <label htmlFor="image" className="text-sm font-medium">
                  Image
                </label>

                <input
                  type="file"
                  id="image"
                  name="image"
                  className="hidden opacity-0"
                  onChange={event => setImage(event.target.files?.[0])}
                  accept="image/*"
                  hidden
                  required
                />

                <div className="flex flex-wrap items-start gap-x-4">
                  <label
                    htmlFor="image"
                    className="relative flex justify-center items-center w-24 h-24 rounded-lg bg-gray-200 dark:bg-zinc-700 overflow-hidden cursor-pointer hover:opacity-80 transition-opacity duration-150"
                  >
                    {image ? (
                      <img
                        src={createReadableImage()}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faAdd}
                        className="text-pri/60 dark:text-pri-d/60"
                        size="xl"
                        fixedWidth
                      />
                    )}
                  </label>

                  <small className="flex flex-1 text-sm text-pri/60 dark:text-pri-d/60">
                    At least 300x300 pixels, JPEG, PNG, GIF, WEBP or AVIF.
                  </small>
                  <CoolButton
                    type="image"
                    saveLoading={saveLoading}
                    clickHandler={saveImage}
                  />
                </div>
              </div>

              {/* name */}
              <div className="flex flex-col gap-y-2">
                <label htmlFor="name" className="text-sm font-medium">
                  Title
                </label>

                <input
                  type="text"
                  id="name"
                  name="name"
                  className="py-3 px-3 rounded-lg border border-pri dark:border-pri-d bg-transparent text-sm focus:border-acc dark:focus:border-acc outline-none transition-colors duration-150"
                  placeholder="Enter name"
                  value={list.name}
                  onChange={event => updateList("name", event.target.value)}
                  required
                />
                <CoolButton
                  type="title"
                  saveLoading={saveLoading}
                  clickHandler={saveTitle}
                />
              </div>

              {/* description */}
              <div className="flex flex-col gap-y-2">
                <label htmlFor="description" className="text-sm font-medium">
                  Description
                </label>

                <textarea
                  id="description"
                  name="description"
                  className="py-3 px-3 h-24 rounded-lg border border-pri dark:border-pri-d bg-transparent text-sm focus:border-acc dark:focus:border-acc outline-none transition-colors duration-150 resize-none"
                  placeholder="Enter description"
                  value={list.description}
                  onChange={event =>
                    updateList("description", event.target.value)
                  }
                />
                <CoolButton
                  type="description"
                  saveLoading={saveLoading}
                  clickHandler={saveDescription}
                />
              </div>

              {/* private */}
              <div className="flex flex-row items-center justify-between gap-x-4">
                <div className="flex flex-col gap-y-1">
                  <label htmlFor="private" className="text-sm font-medium">
                    Private list
                  </label>

                  <p className="text-sm text-pri/60 dark:text-pri-d/60">
                    Make the list visible only to you.
                  </p>
                </div>

                <CoolButton
                  type="private"
                  saveLoading={saveLoading}
                  clickHandler={savePrivate}
                >
                  <span className="text-sm font-medium min-w-8">
                    {list.private ? "Private" : "Public"}
                  </span>
                </CoolButton>
              </div>
            </div>
          )}

          {membersVisibility && (
            <div
              className="flex flex-col gap-y-4 overflow-y-scroll min-h-40 scrollbar-thumb-acc dark:scrollbar-track-zinc-800/5 
                scrollbar scrollbar-w-1 scrollbar-track-inherit scrollbar-thumb-w-1 
                scrollbar-thumb-rounded-full"
            >
              <div>
                {list.users.map(user => {
                  return (
                    <div className="w-full flex flex-row justify-between align-middle items-center py-2 ">
                      <SmallAvatar12 author={user} />
                      <h3>{user}</h3>
                      <CoolButton
                        type={`user${user}`}
                        saveLoading={saveLoading}
                        clickHandler={() => removeUser(user)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <footer className="flex justify-end gap-x-2">
            <button
              className="py-2 px-4 rounded-full bg-pri cursor-pointer dark:bg-pri-d text-sm font-medium hover:bg-neutral-300 dark:hover:bg-neutral-600 transition-all duration-150"
              onClick={() => setMembersVisibility(!membersVisibility)}
            >
              {membersVisibility ? "Manage Details" : "Manage Members"}
            </button>
            <button
              className={classNames(
                "py-2 px-4 rounded-full bg-neutral-200 dark:bg-neutral-700 text-sm font-medium hover:bg-neutral-300 dark:hover:bg-neutral-600 transition-all duration-150",
                {
                  "opacity-60 cursor-not-allowed": uploading || creating
                }
              )}
              onClick={() => setVisibility(false)}
              disabled={uploading || creating}
            >
              Cancel
            </button>
          </footer>
        </div>
      </FloatingHTMLOverlay>
    </FloatingPortal>
  );
}

interface CoolButton {
  type: string;
  saveLoading: any;
  clickHandler: () => {};
  children?: React.ReactNode;
}

function CoolButton({ type, saveLoading, clickHandler, children }: CoolButton) {
  return (
    <button
      type="button"
      className={classNames(
        "mt-1 py-2 px-5 rounded-full border border-pri dark:border-pri-d text-sm font-semibold hover:bg-pri dark:hover:bg-pri-d transition-all duration-150",
        {
          "opacity-50 cursor-not-allowed": saveLoading?.type === type
        }
      )}
      onClick={() => clickHandler()}
      disabled={saveLoading?.type === type}
    >
      {saveLoading?.type === type ? (
        <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
      ) : children ? (
        children
      ) : (
        <FontAwesomeIcon icon={type?.startsWith("user") ? faRemove : faSave} />
      )}
    </button>
  );
}
