import { FloatingHTMLOverlay } from "~/components/FloatingHTMLOverlay";

import {
  faAdd,
  faPen,
  faSpinner,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOnClickOutside from "~/hooks/useClickOutside";
import { useContext, useEffect, useRef, useState } from "react";
import { ParsedAccount, getAvatar } from "~/utils/hive";
import { ImgurData, uploadImage } from "~/utils/images";

import { FloatingPortal } from "@floating-ui/react";
import { CachedList, editList, removeList } from "~/utils/leocache";
import { toast } from "react-toastify";
import classNames from "classnames";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { hivesigner } from "~/utils/hivesigner";
import { useAppStore } from "~/store";

interface ModalProps {
  visibility: boolean;
  setVisibility: (visibility: boolean) => void;
  listDetails: CachedList;
}

export default function RemoveListModal({
  visibility,
  setVisibility,
  listDetails
}: ModalProps) {
  const [{ activeAccount, keys }, isDarkMode] = useAppStore(store => [
    store.account,
    store.settings.dark
  ]);
  const { signature, publicKey, proxy } = keys;

  const modalRef = useRef<HTMLDivElement>(null);

  const remove = async () => {
    await removeList(
      activeAccount.name,
      signature,
      publicKey,
      proxy === "hivesigner",
      {
        list_slug: listDetails.slug
      }
    );
    setVisibility(false);
  };

  if (!visibility) return null;
  return (
    <FloatingPortal>
      <FloatingHTMLOverlay
        onClick={ev => {
          ev.stopPropagation();
        }}
        className="bg-overlay z-[1000] flex justify-center py-[10vh]"
        lockScroll
      >
        <div
          ref={modalRef}
          className="animate-reveal-form relative py-4 px-6 flex flex-col gap-y-6 rounded-xl border-xl bg-pri dark:bg-pri-d border-pri dark:border-pri w-10/12 h-fit pc:w-3/12 pc:max-h-fit md:w-6/12 sm:w-8/12 xs:w-full tbl:min-w-[420px]"
        >
          <header className="flex items-center justify-between pb-4 border-b border-pri dark:border-zinc-700/[.75]">
            <strong className="text-lg">Remove {listDetails.name}</strong>
            <div
              onClick={() => setVisibility(false)}
              className="absolute p-2 h-8 w-8 right-4 flex items-center justify-center rounded-full hover:bg-neutral-200 dark:hover:bg-neutral-700 transition-colors cursor-pointer"
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </header>
          <body className="flex py-4 px-2">
            <p>
              Are you sure want to remove list <b>{listDetails.name}</b>
            </p>
          </body>
          <footer className="flex justify-end gap-x-2">
            <button
              className={classNames(
                "py-2 px-4 rounded-full bg-neutral-200 dark:bg-neutral-700 text-sm font-medium hover:bg-neutral-300 dark:hover:bg-neutral-600 transition-all duration-150"
              )}
              onClick={() => setVisibility(false)}
            >
              Cancel
            </button>
            <button
              onClick={() => remove()}
              className={classNames(
                "py-2 px-4 rounded-full bg-acc text-sm font-medium hover:bg-acc-hov transition-all duration-150"
              )}
            >
              Remove
            </button>
          </footer>
        </div>
      </FloatingHTMLOverlay>
    </FloatingPortal>
  );
}

