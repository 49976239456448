import React, { useContext, useState } from "react";
import { faCheck, faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@remix-run/react";

import Lion from "~/images/explore-lion.png";
import { CachedList, leocache } from "~/utils/leocache";
import { SmallAvatar } from "~/components/format/SmallAvatar";
import classNames from "classnames";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import EditListModal from "~/components/lists/EditListModal";
import RemoveListModal from "~/components/lists/RemoveListModal";
import { useAppStore } from "~/store";

export default function ListsIndex() {
  const [lists, setLists] = React.useState<any[] | null>(null);

  const [activeAccount, { signature, publicKey, proxy }] = useAppStore(
    store => [store.account.activeAccount, store.account.keys]
  );

  React.useEffect(() => {
    if (!activeAccount || !publicKey || !signature) {
      return setLists([]);
    }

    void (async function () {
      try {
        const lists = await leocache.getAllLists(
          activeAccount?.name,
          publicKey,
          signature,
          proxy === "hivesigner"
        );

        setLists(lists);
      } catch {
        setLists([]);
      }
    })();
  }, [signature, publicKey, proxy, activeAccount]);

  return (
    <div className="flex flex-1 flex-col gap-y-1">
      <section className="flex flex-1 flex-col pt-2 pb-6 gap-y-6 border-b border-pri dark:border-pri-d">
        <div className="relative w-full max-w-[40%] min-w-[300px] h-0 mx-auto pb-[50%] overflow-hidden">
          <div className="absolute inset-0 w-full h-full">
            <img
              src={Lion}
              alt=""
              className="w-full h-full object-cover"
              loading="lazy"
            />
          </div>
        </div>

        <div className="flex flex-col justify-start items-start px-5 gap-y-1">
          <h1 className="text-3xl font-bold">
            Explore the{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r to-[#f4401f] from-[#fcbc2b]">
              Lists
            </span>
          </h1>
          <p className="text-sm text-pri/60 dark:text-pri-d/60">
            When you follow a List, you can hear from experts on the topic
            you're most interested in.
          </p>
        </div>
      </section>

      <div className="flex flex-1 flex-col p-5 gap-y-12">
        <OwnedLists />

        <section className="flex flex-1 flex-col gap-y-5">
          <strong className="text-2xl font-bold">Explore new lists</strong>

          {lists === null ? (
            <ListsLoader />
          ) : lists.length > 0 ? (
            <div className="flex flex-col gap-y-2">
              <div className="flex flex-col divide-y divide-solid divide-pri/50 dark:divide-pri-d/50">
                {lists.slice(0, 5).map(list => (
                  <ListPreview key={list.slug} list={list} />
                ))}
              </div>

              <Link
                to="/lists/explore"
                className="py-2 text-acc hover:text-acc-hov hover:bg-pri-hov dark:hover:bg-pri-hov-d transition-colors duration-150"
              >
                See all lists
              </Link>
            </div>
          ) : (
            <div className="text-pri/60 dark:text-pri-d/60 text-sm font-medium">
              There is no list to show.
            </div>
          )}
        </section>
      </div>
    </div>
  );
}

function OwnedLists() {
  const [activeAccount, { signature, publicKey, proxy }] = useAppStore(
    store => [store.account.activeAccount, store.account.keys]
  );

  const [lists, setLists] = React.useState<any[] | null>(null);

  React.useEffect(() => {
    if (!activeAccount || !signature || !publicKey) return;

    void (async function () {
      try {
        const lists = await leocache.getAuthorLists(
          activeAccount?.name,
          publicKey,
          signature,
          proxy === "hivesigner"
        );

        setLists((lists || [])?.reverse());
      } catch {
        setLists([]);
      }
    })();
  }, [signature, publicKey, proxy]);

  const updateLists = () => {
    void (async function () {
      try {
        const lists = await leocache.getAuthorLists(
          activeAccount?.name,
          publicKey,
          signature,
          proxy === "hivesigner"
        );

        setLists(lists);
      } catch {
        setLists([]);
      }
    })();
  };

  return (
    <section className="flex flex-1 flex-col gap-y-5">
      <strong className="text-2xl font-bold">Your lists</strong>

      {lists === null ? (
        <ListsLoader />
      ) : lists?.length > 0 ? (
        <div className="flex flex-col divide-y divide-solid divide-pri dark:divide-pri-d">
          {lists?.map(list => (
            <ListPreview key={list.slug} list={list} />
          ))}
        </div>
      ) : (
        <div className="text-pri/60 dark:text-pri-d/60 text-sm font-medium">
          There is no list you join or follow.
        </div>
      )}
    </section>
  );
}

export function ListPreview({
  list,
  selected,
  author,
  onClick
}: {
  list: CachedList;
  selected?: CachedList | null;
  author: string;
  onClick?: (list: CachedList) => void;
}) {
  const activeAccount = useAppStore(store => [store.account.activeAccount]);
  const [editListVisibility, setEditListVisibility] = useState(false);
  const [removeListVisibility, setRemoveListVisibility] = useState(false);

  if (typeof onClick === "function") {
    return (
      <button
        className="flex items-center gap-x-4 py-3 px-1.5 hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors duration-150"
        onClick={() => onClick(list)}
      >
        <div
          className={classNames("relative flex w-9 h-9 overflow-hidden", {
            "rounded-xl bg-gray-200 dark:bg-zinc-700": !list.image
          })}
        >
          {list.image && (
            <img
              src={list.image}
              alt=""
              className="w-full h-full object-cover"
            />
          )}
        </div>

        <div className="flex flex-1 flex-col justify-start items-start gap-y-0.5">
          <strong className="text-left">{list.name}</strong>

          <div className="flex items-center gap-x-2">
            <div className="flex items-center -space-x-3">
              <Link
                to={`/profile/${list.account}`}
                className="flex items-center gap-x-1.5 hover:underline"
              >
                <span className="relative flex w-6 h-6 rounded-full overflow-hidden">
                  <SmallAvatar author={list.account} size={6} />
                </span>

                <small className="text-xs font-bold">{list.account}</small>
              </Link>
            </div>
          </div>
        </div>

        {selected?.slug === list.slug && (
          <div
            className={classNames(
              "flex justify-center items-center w-6 h-6 rounded-full ml-auto bg-pri-d dark:bg-pri text-pri-d dark:text-pri"
            )}
          >
            <FontAwesomeIcon icon={faCheck} />
          </div>
        )}
      </button>
    );
  }

  return (
    <Link
      to={`/lists/${list.account}/${list.slug}`}
      className="flex items-center gap-x-4 py-3 px-1.5 hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors duration-150"
      prefetch="intent"
    >
      <div
        className={classNames("relative flex w-12 h-12 overflow-hidden", {
          "rounded-xl bg-gray-200 dark:bg-zinc-700": !list.image
        })}
      >
        {list.image && (
          <img src={list.image} alt="" className="w-full h-full object-cover" />
        )}
      </div>

      <div className="flex flex-1 flex-col gap-y-0.5">
        <strong>{list.name}</strong>

        <div className="flex items-center gap-x-2">
          <div className="flex items-center -space-x-3">
            <Link
              to={`/profile/${list.account}`}
              className="flex items-center gap-x-1.5 hover:underline"
            >
              <span className="relative flex w-6 h-6 rounded-full overflow-hidden">
                <SmallAvatar author={list.account} size={6} />
              </span>

              <small className="text-xs font-bold">{list.account}</small>
            </Link>
          </div>
          <p className="text-pri/50 dark:text-pri-d/50 text-xs">
            {list.followers} Followers · {list.users.length} Members
          </p>
        </div>
      </div>

      <EditListModal
        listDetails={list}
        visibility={editListVisibility}
        setVisibility={setEditListVisibility}
      />
      <RemoveListModal
        listDetails={list}
        visibility={removeListVisibility}
        setVisibility={setRemoveListVisibility}
      />
      {list.account === activeAccount?.name && (
        <>
          <button
            type="button"
            className="flex items-center justify-center w-8 h-8 bg-pri-d dark:bg-pri text-pri-d dark:text-pri rounded-full hover:opacity-80 transition-opacity duration-150"
            onClick={event => {
              setEditListVisibility(true);
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            <FontAwesomeIcon icon={faEdit} fixedWidth />
          </button>
          <button
            type="button"
            className="flex items-center justify-center w-8 h-8 bg-red-400 dark:bg-red-600 text-pri-d dark:text-pri rounded-full hover:opacity-80 transition-opacity duration-150"
            onClick={event => {
              setRemoveListVisibility(true);
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            <FontAwesomeIcon icon={faRemove} fixedWidth />
          </button>
        </>
      )}
    </Link>
  );
}

export function ListsLoader() {
  return (
    <div className="flex flex-1 flex-col divide-y divide-pri dark:divide-pri-d">
      {[...Array(3).keys()].map(i => (
        <div
          key={i}
          className="flex items-center gap-x-4 py-3 px-1.5 animate-pulse"
        >
          <div className="flex w-12 h-12 rounded-xl bg-gray-200 dark:bg-zinc-700" />

          <div className="flex flex-1 flex-col gap-y-2">
            <span className="w-1/3 h-3 rounded-md bg-gray-200 dark:bg-zinc-700" />
            <span className="w-1/4 h-1.5 rounded-md bg-gray-200 dark:bg-zinc-700" />
          </div>

          <div className="flex w-8 h-8 bg-gray-200 dark:bg-zinc-700 rounded-full" />
        </div>
      ))}
    </div>
  );
}
